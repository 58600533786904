import { env } from '../configs/EnvironmentConfig';
import SupabaseConfig from '../configs/SupabaseConfig';

let _results = [];

// Helper function to create the headers
const getHeaders = () => {
  const myHeaders = new Headers();
  myHeaders.append("apiKey", SupabaseConfig.supabaseAnonApiKey);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept-Profile", "TitulinoApi_v1");
  myHeaders.append("Content-Profile", "TitulinoApi_v1");
  
  return myHeaders;
};


export const getCountries = async (whoCalledMe) => {      

  if (whoCalledMe) {

    const requestOptions = {
      method: "GET",
      headers: getHeaders(),
      redirect: "follow"
    };

    const countriesUrl = `${SupabaseConfig.baseApiUrl}/GetCountries`;

    try {
      const response = await fetch(countriesUrl, requestOptions);
      const apiResult = await response.json();
      return apiResult?.length > 0 ? apiResult : _results;
      
    } catch (error) {
      console.log(`Error Retrieving API payload in getCountries: from ${whoCalledMe}`);
      console.error(error);
      return _results; // Might be better to return a handled response
    }
  }

  return _results;
};

export const getCountryDivisionByCountryId = async (countryId, whoCalledMe) => {      

  if (countryId) {
  
    const requestOptions = {
      method: "GET",
      headers: getHeaders(),
      redirect: "follow"
    };
  
      // Base URL
      let countryDivisionUrl = `${SupabaseConfig.baseApiUrl}/GetCountryDivisionByCountryId`;

      // Conditionally append countryId as a query parameter
      if (countryId) {
        countryDivisionUrl += `?p_country_id=${encodeURIComponent(countryId)}`;
      }

    try {
      const response = await fetch(countryDivisionUrl, requestOptions);
      const apiResult = await response.json();
      return apiResult?.length > 0 ? apiResult : _results;
    } catch (error) {
      console.log(`Error Retrieving API payload in getCountryDivisionByCountryId: from ${whoCalledMe}`);
      console.error(error);
      return _results; // Might be better to return a handled response
    }
  }

  return _results;
};

export const getAvailableCourses = async (currentDateTime, whoCalledMe) => {      

    if (whoCalledMe) {
    
      const requestOptions = {
        method: "GET",
        headers: getHeaders(),
        redirect: "follow"
      };
    
          // Base URL
          let currentAvailableCoursesUrl = `${SupabaseConfig.baseApiUrl}/GetAvailableCourses`;

          // Conditionally append currentDateTime as a query parameter
          if (currentDateTime) {
            currentAvailableCoursesUrl += `?currentDateTime=${encodeURIComponent(currentDateTime)}`;
          }

      try {
        const response = await fetch(currentAvailableCoursesUrl, requestOptions);
        const apiResult = await response.json();
        return apiResult?.length > 0 ? apiResult : _results;
      } catch (error) {
        console.log(`Error Retrieving API payload in getAvailableCourses: from ${whoCalledMe}`);
        console.error(error);
        return _results; // Might be better to return a handled response
      }
    }
  
    return _results;
};

export const getSelfDeterminedLanguageLevelCriteria = async (whoCalledMe) => {      

  if (whoCalledMe) {

    const requestOptions = {
      method: "GET",
      headers: getHeaders(),
      redirect: "follow"
    };
  
        // Base URL
    const selfDeterminedLanguageLevelCriteriaUrl = `${SupabaseConfig.baseApiUrl}/GetSelfDeterminedLanguageLevelCriteria`;

    try {
      const response = await fetch(selfDeterminedLanguageLevelCriteriaUrl, requestOptions);
      const apiResult = await response.json();
      return apiResult?.length > 0 ? apiResult : _results;
    } catch (error) {
      console.log(`Error Retrieving API payload in getSelfDeterminedLanguageLevelCriteria: from ${whoCalledMe}`);
      console.error(error);
      return _results; // Might be better to return a handled response
    }
  }

  return _results;
};

export const getQuickEnrolleeInfo = async (email, dob, whoCalledMe) => {      

  if (email && dob) {

    const requestOptions = {
      method: "GET",
      headers: getHeaders(),
      redirect: "follow"
    };
  
        // Base URL
    const enrolledContactUrl = `${SupabaseConfig.baseApiUrl}/GetEnrolledContact`;

    const temp = [
      {
        "sex": "M",
        "email": "largo1019@gmail.com",
        "countryOfBirthId": "MX",
        "countryOfBirthName": "Mexico",
        "countryOfBirthAlpha3": "MEX",
        "countryOfResidencyId": "MX",
        "countryDivisionIdBirth": null,
        "countryOfResidencyName": "Mexico",
        "countryDivisionBirthName": null,
        "countryOfBirthNativeName": "México",
        "countryOfResidencyAlpha3": "MEX",
        "personalCommunicationName": "Christian Alberto",
        "countryDivisionIdResidency": null,
        "countryDivisionResidencyName": null,
        "countryOfResidencyNativeName": "México"
      }
    ]


    try {
      // const response = await fetch(enrolledContactUrl, requestOptions);
      // const apiResult = await response.json();
      // return apiResult?.length > 0 ? apiResult : _results;
      return temp[0];
    } catch (error) {
      console.log(`Error Retrieving API payload in getQuickEnrolleeInfo: from ${whoCalledMe}`);
      console.error(error);
      return _results; // Might be better to return a handled response
    }
  }

  return _results;
};
  
const TitulinoRestService = {
  getCountries,
  getAvailableCourses,
  getSelfDeterminedLanguageLevelCriteria,
  getCountryDivisionByCountryId,
  getQuickEnrolleeInfo
};

export default TitulinoRestService;
